    <template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getTemplateVariable">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly"  color="indigo" dark fab fixed top right class="v-btn--add-form-top">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>

                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="12" class="title_menu mt-1">
                                        {{  $t('template_variables') }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <TabsNav :items="items"/>
                                    </v-col>
                                </v-row>



                            </v-card-text>

                        </v-card>

                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table
                            item-key="id" ref="table" :headers="headers" :items="template_variable"
                            :options.sync="options" disable-pagination
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDir"
                            :loading="loading" :locale="lang" :loading-text="$t('loading_please_wait')"
                            hide-default-footer
                            class="sortable-table elevation-0"
                        >

                            <template v-slot:item.name="{ item }">
                                <div>
                                    <span class="cursor-pointer font_weight_600" style="font-size: 16px" @click="copyText(item)" >
                                        {{  item.name }}
                                    </span>
                                    <v-icon small @click="copyText(item)">mdi-content-copy</v-icon>
                                </div>
                            </template>



                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>



    </v-container>
</template>
<script>



import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: 'DealStep',
    components: {
        SettingMenu,
        TabsNav
    },
    props: {
        businessProcessId: {
            default: null
        }
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: this.$t('deal_step_creation'),
            progress: 0,
            language: null,
            loading: false,
            sortBy: "sort",
            sortDir: false,
            options: {},
            errors: {},
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: false,
                    value: "name"
                },
            ],
            filter_show: false,
            items: [
                {
                    title: 'uveds',
                    icon: 'icon-clients',
                    route: {
                        name: 'template_variable',
                        params: {
                            type: 'uved'
                        }
                    },
                    permissions: ['administrator', 'director'],
                },
                {
                    title: 'tasks',
                    icon: 'icon-doc',
                    route: {
                        name: 'template_variable',
                        params: {
                            type: 'task'
                        }
                    },
                    permissions: ['administrator', 'director'],
                },
                {
                    title: 'deals',
                    icon: 'icon-taks',
                    route: {
                        name: 'template_variable',
                        params: {
                            type: 'deal'
                        }
                    },
                    permissions: ['administrator', 'director'],
                },


            ],
            template_variable: [],
            type: this.$route.params.type,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang','BusinessProcessId']),
        languages() {
            return this.listLanguages
        },

    },

    async mounted() {
        this.language = this.languages[this.tab]
        await this.checkCreate()
        await this.getTemplateVariable();

    },

    watch: {
        options: {
            handler() {
                this.getTemplateVariable()
            },
            deep: false
        },
        '$route.params.type': {
        handler(newType) {
            this.type = newType;
            this.getTemplateVariable();
        },
        immediate: true // Выполнить обработчик сразу при создании компонента
    }
    },
    methods: {
        async copyText(item){
            await navigator.clipboard.writeText(item.code);
            this.$toastr.success(this.$t('value_is_copied'))
        },

        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5Sort' : 'rowMobiClass';
        },
        setLanguage(val) {
            this.language = this.languages[val]
        },
        checkCreate() {
            this.heading = this.$t('deal_step_creation')

        },

        async getTemplateVariable() {
            var _this = this;
            this.loading = true
            let params = {}
            params.type = this.$route.params.type;
            await this.$http
                .get("admin/template_variable", {
                    params: params,
                })
                .then(res => {
                    this.template_variable = res.body.data
                })
                .catch(err => {
                    this.template_variable = []
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },


    }
}
</script>
